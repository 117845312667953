import React from "react"
import { navigate, graphql, Link } from "gatsby"
import WordCloud from "react-d3-cloud"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Tags = ({ data }) => {
  let wordCloud = []

  const tags = data.allTags.edges.sort((a, b) =>
    a.node.name.localeCompare(b.node.name)
  )

  const cards = data.allPosts.edges

  const getWordCloudWidth = () => {
    if (typeof window === "undefined") return
    if (window.innerWidth > 1600) {
      return window.innerWidth / 1.84
    } else if (window.innerWidth > 1440) {
      return window.innerWidth / 1.53
    } else if (window.innerWidth > 1366) {
      return window.innerWidth / 1.38
    }
    return window.innerWidth - 50
  }

  const renderCloud = () => {
    if (typeof document === "undefined") return

    const fontSizeMapper = word => Math.log2(word.value) * 10

    return (
      <WordCloud
        width={getWordCloudWidth()}
        fontSizeMapper={fontSizeMapper}
        data={wordCloud}
        padding={2}
        onWordClick={e => navigate(`/tags/${e.slug}`)}
      />
    )
  }

  const filterCardsByTag = tag => {
    let filteredCards = []

    cards.forEach(card => {
      card.node.tags.map(t => {
        if (t.slug === tag.slug) {
          filteredCards.push(card.node)
        }
      })
    })

    wordCloud.push({
      text: tag.name,
      value: filteredCards.length,
      slug: tag.slug,
    })

    return <span>{filteredCards.length} leituras</span>
  }

  return (
    <Layout>
      <SEO
        title="Tags"
        description="Em nome de Allah, O Clemente, O Misericordioso Fundado em 2014, o Iqara Islam nasceu como um portal com o objetivo de difundir o conhecimento sobre a religião Islâmica aos falantes da língua portuguesa, abordando temas diversificados, que atendam a demanda do público por conhecimento, procurando sempre fornecer conteúdos únicos e soluções para o dia"
        url={`${process.env.GATSBY_HOST_URL}/tags`}
      />

      <div
        class="content-grid page-grid quem-somos"
        style={{
          maxWidth: "1100px",
          margin: "0 auto",
          gridTemplateColumns: "1fr",
        }}
      >
        <div class="content content-custom" style={{ maxWidth: "100%" }}>
          <div>
            <h1>Tags</h1>
            <p>
              Procure entre nossas tags mais utilizadas pelos temas de maior
              interesse
            </p>
          </div>

          <div className="hashtags-content">
            <div className="hashtags-cloud">{renderCloud()}</div>

            <h2>Explore</h2>
            <div className="hashtags-list">
              {tags.map(tag => (
                <Link
                  to={`/tags/${tag.node.slug}`}
                  className="hashtags-list-item"
                >
                  <h3 style={{ marginTop: 0, marginBottom: 5 }}>
                    {tag.node.name}
                  </h3>
                  {filterCardsByTag(tag.node)}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Tags

export const query = graphql`
  query {
    allTags {
      edges {
        node {
          name
          slug
        }
      }
    }

    allPosts {
      edges {
        node {
          name
          slug
          tags {
            name
            slug
          }
        }
      }
    }
  }
`
